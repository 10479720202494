import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import carReducer from './slices/car'; 
import notificationReducer from './slices/notification';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['car'],
};
const rootReducer = combineReducers({
  car: carReducer, 
  notification:notificationReducer,
});

export { rootPersistConfig, rootReducer };

import { lazy } from 'react';
import GuestGuard from '../guards/GuestGuard';

const Login = lazy(() => import('../pages/auth/Login'));
const VerifyCode = lazy(() => import('../pages/auth/VerifyCode'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));

const authRoutes = {
  path: 'auth',
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: 'verify', element: (<GuestGuard> <VerifyCode /></GuestGuard>) },
    { path: 'forgot-password', element: (<GuestGuard> <ForgotPassword /></GuestGuard>) },
  ],
};

export default authRoutes;

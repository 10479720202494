import React from 'react';

function DeleteData() {
  return (
    <div>
      <header>
        <h1>User Data Deletion Instructions</h1>
      </header>
      <main>
        <section>
          <h2>Introduction</h2>
          <p>We value your privacy and are committed to ensuring that you can manage your data. Here are the instructions for requesting the deletion of your data.</p>
        </section>
        <section>
          <h2>Steps to Request Data Deletion</h2>
          <ol>
            <li>Contact our support team via email at support@aslaa.mn.</li>
            <li>Include your account details and a request for data deletion.</li>
            <li>Our team will verify your request and confirm the deletion of your data within 30 days.</li>
          </ol>
        </section>
        <section>
          <h2>Contact Information</h2>
          <p>If you have any questions or concerns about data deletion, please contact us at support@aslaa.mn.</p>
        </section>
      </main>
    </div>
  );
}

export default DeleteData;

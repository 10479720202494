import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import LogoOnlyLayout from '../layout/LogoOnlyLayout';

const NotFound = lazy(() => import('../pages/Page404'));

const errorRoutes = {
  path: '*',
  element: <LogoOnlyLayout />,
  children: [
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ],
};

export default errorRoutes;

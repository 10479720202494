import React from 'react';

const PrivacyPolicy = () => {
    const currentDate = new Date().toLocaleDateString();

    return (
        <div>
            <h1>Privacy Policy</h1>
            <p>Last updated: {currentDate}</p>

            <h2>Summary of Key Points</h2>
            <p>This Privacy Policy outlines how New Elec Service LLC ('we,' 'us,' or 'our') collects and processes your personal information when you access our services via our website, mobile application, and other interactions.</p>

            <h2>Table of Contents</h2>
            <ul>
                <li>What Information Do We Collect?</li>
                <li>How Do We Process Your Information?</li>
                <li>When and With Whom Do We Share Your Personal Information?</li>
                <li>Do We Use Cookies and Other Tracking Technologies?</li>
                <li>How Long Do We Keep Your Information?</li>
                <li>How Do We Keep Your Information Safe?</li>
                <li>Do We Collect Information from Minors?</li>
                <li>What Are Your Privacy Rights?</li>
                <li>Controls for Do-Not-Track Features.</li>
                <li>Do We Make Updates to This Notice?</li>
                <li>How Can You Contact Us About This Notice?</li>
                <li>How Can You Review, Update, or Delete the Data We Collect from You?</li>
            </ul>

            <h2>What Information Do We Collect?</h2>
            <p>We may collect the following types of information:
                <ul>
                    <li>Personal Information provided by you: phone numbers, usernames, passwords.</li>
                    <li>Application Data: Geolocation Information, Push Notifications.</li>
                </ul>
            </p>

            <h2>How Do We Process Your Information?</h2>
            <p>We use the information we collect for various purposes, including:
                <ul>
                    <li>To provide and maintain our service.</li>
                    <li>To notify you about changes to our service.</li>
                    <li>To allow you to participate in interactive features of our service when you choose to do so.</li>
                    <li>Account creation and authentication.</li>
                    <li>Compliance with legal obligations.</li>
                </ul>
            </p>

            <h2>When and With Whom Do We Share Your Personal Information?</h2>
            <p>We do not share your personal information with third parties except:
                <ul>
                    <li>With your consent.</li>
                    <li>To comply with legal obligations.</li>
                    <li>Business Transfers.</li>
                    <li>Google Maps Platform APIs.</li>
                </ul>
            </p>

            <h2>Do We Use Cookies and Other Tracking Technologies?</h2>
            <p>Our service may use cookies and similar tracking technologies to enhance user experience.</p>

            <h2>How Long Do We Keep Your Information?</h2>
            <p>We retain your personal information only for as long as necessary to fulfill the purposes we collected it for.</p>

            <h2>How Do We Keep Your Information Safe?</h2>
            <p>We implement a variety of security measures to maintain the safety of your personal information.</p>

            <h2>Do We Collect Information from Minors?</h2>
            <p>We do not knowingly collect personal information from children under the age of 13.</p>

            <h2>What Are Your Privacy Rights?</h2>
            <p>You have the right to:
                <ul>
                    <li>Access your personal data.</li>
                    <li>Request correction of your personal data.</li>
                    <li>Request deletion of your personal data.</li>
                    <li>Withdraw consent. For managing your personal information, visit <a href="https://www.aslaa.mn/delete-data">this link</a>.</li>
                </ul>
            </p>

            <h2>Controls for Do-Not-Track Features</h2>
            <p>Our service does not currently respond to DNT signals.</p>

            <h2>Do We Make Updates to This Notice?</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy at <a href="https://www.aslaa.mn/privacy-policy">this link</a>.</p>

            <h2>How Can You Contact Us About This Notice?</h2>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <p>Email: electronics@elec.mn</p>
            <p>Address: New Elec Service LLC<br />Ulaanbaatar, Mongolia</p>

            <h2>How Can You Review, Update, or Delete the Data We Collect from You?</h2>
            <p>For managing your personal information, please visit <a href="https://www.aslaa.mn/delete-data">this link</a>.</p>
        </div>
    );
};

export default PrivacyPolicy;

import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// hooks 
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  // const { themeMode, themeDirection } = useSettings();
  // const isLight = themeMode === 'light';

      const themeOptions = useMemo(
        () => ({
          palette: palette.dark,
          typography,
          breakpoints,
          shape: { borderRadius: 8 },
          // shadows:  shadows.dark,
        }),
        []
      );

  const theme = createTheme(themeOptions);
  // theme.components = componentsOverride(theme);
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}

import React from 'react';
import { SnackbarProvider } from 'notistack';

const NotistackProvider = ({ children }) => {
  return (
    <SnackbarProvider maxSnack={3}>
      {children}
    </SnackbarProvider>
  );
};

export default NotistackProvider;

import { lazy } from 'react';
import AuthGuard from '../guards/AuthGuard';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';
import DeleteData from '../pages/DeleteData';

const Home = lazy(() => import('../pages/Home'));
const LandingPage = lazy(() => import('../pages/Landing'));
const PinCode = lazy(() => import('../pages/PinCode'));
const TimeCommand = lazy(() => import('../pages/TimeCommand'));
const HelpPage = lazy(() => import('../pages/helpPage'));
const LogManagement = lazy(() => import('../pages/LogManagement'));
const LogLicense = lazy(() => import('../pages/InvoiceLog'));
const Notification = lazy(() => import('../pages/Notification'));
const CarLocationsWithRouter = lazy(() => import('../pages/CarLocationsWithRouter'));
const SimCardLogs = lazy(() => import('../pages/SimCardLog'));
const GpsHistoryOnMap = lazy(() => import('../pages/GpsHistoryOnMap'));
const ConfigureDrivers = lazy(() => import('../pages/ConfigureDrivers'));
const LicenseProfile = lazy(() => import('../pages/LicenseProfile'));
const DriverProfile = lazy(() => import('../pages/DriverProfile'));
const DeviceRegister = lazy(() => import('../pages/DeviceRegister'));
const ConfigGPS = lazy(() => import('../pages/ConfigGPS'));
const Order = lazy(() => import('../pages/Order'));

const mainRoutes = {
  path: '',
  children: [
    { element: (<LandingPage />), index: true },
    { path: 'home', element: (<AuthGuard> <Home /> </AuthGuard>) },
    { path: 'help', element: <HelpPage /> },
    { path: 'device-register', element: (<AuthGuard> <DeviceRegister /> </AuthGuard>) },
    { path: 'license-profile', element: (<AuthGuard> <LicenseProfile /> </AuthGuard>) },
    { path: 'pin-code', element: (<AuthGuard> <PinCode /> </AuthGuard>) },
    { path: 'time-command', element: (<AuthGuard> <TimeCommand /> </AuthGuard>) },
    { path: 'log-management', element: (<AuthGuard> <LogManagement /> </AuthGuard>) },
    { path: 'log-map', element: (<AuthGuard> <CarLocationsWithRouter /> </AuthGuard>) },
    { path: 'log-sim', element: (<AuthGuard> <SimCardLogs /> </AuthGuard>) },
    { path: 'log-gps', element: (<AuthGuard> <GpsHistoryOnMap /> </AuthGuard>) },
    { path: 'configure-driver', element: (<AuthGuard> <ConfigureDrivers /> </AuthGuard>) },
    { path: 'configure-gps/:id', element: (<AuthGuard> <ConfigGPS /> </AuthGuard>) },
    { path: 'log-license', element: (<AuthGuard> <LogLicense /> </AuthGuard>) },
    { path: 'Order', element: (<AuthGuard> <Order /> </AuthGuard>) },
    { path: 'driver-profile', element: (<AuthGuard> <DriverProfile /> </AuthGuard>) },
    { path: 'notification', element: (<AuthGuard> <Notification /> </AuthGuard>) },
    { path: 'privacy-policy', element: <PrivacyPolicy /> },
    { path: 'terms-of-service', element: <TermsOfService /> },
    { path: 'delete-data', element: <DeleteData /> },
  ]
};

export default mainRoutes;

import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
      <p style={{ margin: '0 10px' }}>&copy; 2025 Elec.mn All Rights Reserved.</p>
      <nav>
        <Link to="/privacy-policy" style={{ margin: '0 10px' }}>Privacy Policy</Link>
        <Link to="/terms-of-service" style={{ margin: '0 10px' }}>Terms of Service</Link>
        <Link to="/delete-data" style={{ margin: '0 10px' }}>Delete Data</Link>
      </nav>
    </footer>
  );
}

export default Footer;

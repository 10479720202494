import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

export const firebaseConfig = {
  apiKey: "AIzaSyDA_x4YElOAVmT4rS3B-xcmCzhvefDTOrI",
  authDomain: "rccdemo-41279.firebaseapp.com",
  projectId: "rccdemo-41279",
  storageBucket: "rccdemo-41279.appspot.com",
  messagingSenderId: "963013875719",
  appId: "1:963013875719:web:f9511f343bceb59b06f2a2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Setup Messaging
export const messaging = getMessaging(app);

// Optionally export getToken if you need it elsewhere
export { getToken };
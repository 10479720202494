import React from 'react';

function TermsOfService() {
  return (
    <div>
      <header>
        <h1>Terms of Service</h1>
      </header>
      <main>
        <section>
          <h2>Introduction</h2>
          <p>Welcome to our Terms of Service page. These terms outline the rules and regulations for the use of our website and services.</p>
        </section>
        <section>
          <h2>Acceptance of Terms</h2>
          <p>By accessing this website, you accept these terms and conditions in full. Do not continue to use the website if you do not agree to all of the terms and conditions stated on this page.</p>
        </section>
        <section>
          <h2>License</h2>
          <p>Unless otherwise stated, we own the intellectual property rights for all material on the website. All intellectual property rights are reserved.</p>
        </section>
        <section>
          <h2>User Obligations</h2>
          <p>Users must not use the website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website.</p>
        </section>
      </main>
    </div>
  );
}

export default TermsOfService;

import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  isLoading: false,
  error: null,
  status: null, 
  routines:null,
}
const slice = createSlice({
  name: 'car',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    }, 
    hasError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setCarStatus(state, action) {
      state.isLoading = false;
      state.status = action.payload;
    }, 
    setRoutines(state,action){
      state.routines = action.payload;
    }
  }
});

export default slice.reducer;
export const {
  setCarStatus,
  setRoutines, 
} = slice.actions; 
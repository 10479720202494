import { lazy } from 'react';
import AuthGuard from '../guards/AuthGuard';

const DeviceEdit = lazy(() => import('../pages/admin/DeviceEdit'));
const UserManage = lazy(() => import('../pages/admin/UserManage'));
const TransactionLogs = lazy(() => import('../pages/admin/TransactionLogs'));
const WithdawRequests = lazy(() => import('../pages/admin/WithdrawLogs'));
const Orders = lazy(() => import('../pages/admin/OrderList'));
const adminRoutes = {
  path: 'admin',
  children: [
    { path: 'device/:id', element: <AuthGuard> <DeviceEdit /></AuthGuard> },
    { path: 'user-manage', element: <AuthGuard><UserManage /></AuthGuard> },
    { path: 'orders', element: <AuthGuard> <Orders /></AuthGuard> },
    { path: 'transactions', element: <AuthGuard> <TransactionLogs /></AuthGuard> },
    { path: 'withdraw-request', element: <AuthGuard> <WithdawRequests /></AuthGuard> },
  ],
};

export default adminRoutes;
